import React from "react";
import { useParams } from "react-router";
import { Button, notification, Tooltip } from "antd";
import { getPrintTemplate, getPrintDownloadData } from "../../services/generic";
import { useGlobalContext } from "../../lib/storage";
import PrintIcon from "../../assets/images/print.svg";
import PrintIconNew from "../../assets/images/printNew.svg";
import "./style.css";
import ThemeJson from "../../constants/UIServer.json"

const Print = (props) => {
  const { globalStore } = useGlobalContext();
  const Themes = ThemeJson;
  const { headerTabId, setLoadingRecordWindow } = props;
  const { recordId,windowId } = useParams();
  const listActionButtons = {
    height: "32px",
    width: "30px",
    background: "#fff",
    border: "0.5px solid #d9d9d9",
    cursor: "pointer",
    marginRight: "5px ",
    // display:"flex",
    justifyContent:"center",
    alignItems:"center",
    description: "list icon buttons on the right hand side"
  }

  const printTemplate = async () => {
    try {
      setLoadingRecordWindow(true);
      const printTemplateData = await getPrintTemplate(windowId,headerTabId, recordId);
      if (printTemplateData.data.data.reportTemplate === null || printTemplateData.data.data.reportTemplate === "null" || printTemplateData.data.data.reportTemplate === "") {
        notification.info({
          message: "File Not Found..!!!!",
        });
        setLoadingRecordWindow(false);
      } else {
        getPrintCommand(printTemplateData.data.data.reportTemplate);
      }
    } catch (error) {
      console.error("Error", error);
      setLoadingRecordWindow(false);
    }
  };

  const getPrintCommand = async (fileName) => {
    const downloadPrintData = await getPrintDownloadData(fileName);
    const fileURL = window.URL.createObjectURL(new Blob([downloadPrintData.data]));
    const link = document.createElement("a");
    link.setAttribute("id", "downloadlink");
    link.href = fileURL;
    link.setAttribute("download", `${fileName}`);
    link.click();
    setLoadingRecordWindow(false);
  };

  return (
    <Tooltip  placement="top" title="Print">
    <Button className="listHeaderButtons" style={listActionButtons}>
      <img onClick={printTemplate} style={{cursor:"pointer",marginLeft:"-0.65em",marginBottom:"5px"}} src={PrintIconNew} alt="invoice" /> &nbsp;
      </Button>
  </Tooltip>
  );
};

export default Print;
